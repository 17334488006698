import React from 'react'

const CartCoulmns = () => {
  return (
   
          <thead>
            <tr>
              <th>Product</th>
              <th>Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>remove</th>
              <th>total</th>
            </tr>
          </thead>
      
  )
}

export default CartCoulmns
